import {
  useMemo,
  useEffect,
  useImperativeHandle,
  ForwardRefRenderFunction,
  useState,
  useRef,
} from 'react';
import {
  useIFrame,
  ISendMessage,
  IFrameEvent,
} from '../../../../providers/useIFrame/useIFrame';
import { usePrevious } from '../../../../providers/usePrevious';
import {
  GoogleMapProps,
  IGoogleMapsImperativeActions,
  GoogleMapSDKActions,
  GoogleMapMarker,
} from '../../GoogleMap.types';
import { usePromises } from './usePromise';
import { fillLocationsWithIcon, shouldKeepMarkers } from './utils';
import constants from './constants';

type GoogleMapsRef = Parameters<
  ForwardRefRenderFunction<IGoogleMapsImperativeActions, GoogleMapProps>
>[1];

type MessageHandlersByType = Record<string, (payload: any) => void>;
type EventHandlersByType = Record<
  string,
  (args: { event: IFrameEvent; _sendMessage: ISendMessage }) => void
>;

export function useGoogleIFrame(
  compRef: GoogleMapsRef,
  mapData: GoogleMapProps['mapData'],
  {
    onUpdateZoom,
    onUpdateCenter,
    onMarkerClicked,
    onMapClicked,
  }: Partial<GoogleMapSDKActions>,
): [(node: HTMLIFrameElement) => void] {
  const corvidModifiedLocations = useRef<
    GoogleMapProps['mapData']['locations'] | null
  >(null);

  const [createSetCenterPromise, resolveSetCenterPromises] = usePromises();
  const [createSetZoomPromise, resolveSetZoomPromises] = usePromises();
  const [createGetMarkersPromise, resolveGetMarkersPromise] =
    usePromises<Array<GoogleMapMarker>>();
  const [createFitBoundsPromise, resolveFitBoundsPromises] = usePromises();

  const [loaded, setLoaded] = useState(false);

  const modifiedMapData = useMemo(
    () => fillLocationsWithIcon(mapData),
    [mapData],
  );

  const prevMapData = usePrevious(modifiedMapData);

  const messageHandlersByType: MessageHandlersByType = {
    [constants.MESSAGE_SET_CENTER_FINISHED]: () => resolveSetCenterPromises(),
    [constants.MESSAGE_FIT_BOUNDS_FINISHED]: () => resolveFitBoundsPromises(),
    [constants.MESSAGE_CENTER_UPDATED]: payload => onUpdateCenter?.(payload),
    [constants.MESSAGE_SET_ZOOM_FINISHED]: () => resolveSetZoomPromises(),
    [constants.MESSAGE_ZOOM_UPDATED]: payload =>
      onUpdateZoom?.({ zoom: payload }),
    [constants.MESSAGE_MARKER_CLICKED]: payload =>
      onMarkerClicked?.({ type: 'markerClicked', ...payload }),
    [constants.MESSAGE_MAP_CLICKED]: ({ longitude, latitude, ...rest }) =>
      onMapClicked?.({
        type: 'mapClicked',
        location: { longitude, latitude },
        ...rest,
      }),
    [constants.MESSAGE_MARKERS]: payload => resolveGetMarkersPromise(payload),
  };

  const eventHandlersByType: EventHandlersByType = {
    [constants.EVENT_LOAD]: ({ _sendMessage }) => {
      _sendMessage({
        type: constants.MESSAGE_SET_INITIAL_LOCATIONS,
        data: JSON.stringify(modifiedMapData),
      });
      setLoaded(true);
    },

    [constants.EVENT_MESSAGE]: ({ event }) => {
      if (typeof event.payload === 'string') {
        const { type, data } = JSON.parse(event.payload);
        messageHandlersByType[type]?.(data);
      }
    },
  };

  const reducer = (event: IFrameEvent, _sendMessage: ISendMessage) =>
    eventHandlersByType[event.type]?.({
      event,
      _sendMessage,
    });

  const [ref, sendMessage] = useIFrame({ reducer });

  useImperativeHandle(compRef, () => ({
    setMapCenter: (longitude, latitude) => {
      const setCenterPromise = createSetCenterPromise();
      sendMessage({
        type: constants.MESSAGE_SET_CENTER,
        data: JSON.stringify({ longitude, latitude }),
      });
      return setCenterPromise;
    },
    fitBounds: ({ north, east, west, south }) => {
      const fitBoundsPromise = createFitBoundsPromise();
      sendMessage({
        type: constants.MESSAGE_FIT_BOUNDS,
        data: JSON.stringify({ north, east, west, south }),
      });
      return fitBoundsPromise;
    },
    setMapZoom: zoom => {
      const setZoomPromise = createSetZoomPromise();
      sendMessage({
        type: constants.MESSAGE_SET_ZOOM,
        data: zoom,
      });
      return setZoomPromise;
    },
    getVisibleMarkers: () => {
      const getMarkersPromise = createGetMarkersPromise();
      sendMessage({ type: constants.MESSAGE_GET_MARKERS });
      return getMarkersPromise;
    },
    setMarkers: locations => {
      corvidModifiedLocations.current = locations;
      sendMessage(JSON.stringify({ ...modifiedMapData, locations }));
    },
  }));

  useEffect(() => {
    if (!loaded) {
      return;
    }

    /**
     * If corvid modified the locations,
     * we should use locations state from corvid instead.
     */
    const corvidLocations = corvidModifiedLocations.current;
    const mapDataLocations = modifiedMapData.locations;
    const locations = corvidLocations || mapDataLocations;

    /**
     * In Editor settings panel we don't want to update markers/locations
     * if some knobs like zoom are being changed. This improves runtime
     * performance while playing around in the settings panel.
     */
    const shouldKeepLocations =
      !!prevMapData && shouldKeepMarkers(locations, prevMapData.locations);

    sendMessage(
      JSON.stringify({
        ...modifiedMapData,
        locations,
        shouldKeepMarkers: shouldKeepLocations,
      }),
    );
  }, [modifiedMapData, sendMessage, loaded, prevMapData]);

  return [ref];
}
