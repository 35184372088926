export default {
  EVENT_LOAD: 'load',
  EVENT_MESSAGE: 'message',
  MESSAGE_SET_INITIAL_LOCATIONS: 'SET_INITIAL_LOCATIONS',
  MESSAGE_SET_CENTER: 'SET_CENTER',
  MESSAGE_CENTER_UPDATED: 'CENTER_UPDATED',
  MESSAGE_SET_CENTER_FINISHED: 'SET_CENTER_FINISHED',
  MESSAGE_SET_ZOOM: 'SET_ZOOM',
  MESSAGE_ZOOM_UPDATED: 'ZOOM_UPDATED',
  MESSAGE_SET_ZOOM_FINISHED: 'SET_ZOOM_FINISHED',
  MESSAGE_MARKER_CLICKED: 'MARKER_CLICKED',
  MESSAGE_MAP_CLICKED: 'MAP_CLICKED',
  MESSAGE_GET_MARKERS: 'GET_MARKERS',
  MESSAGE_MARKERS: 'MARKERS',
  MESSAGE_FIT_BOUNDS: 'FIT_BOUNDS',
  MESSAGE_FIT_BOUNDS_FINISHED: 'FIT_BOUNDS_FINISHED',
};
